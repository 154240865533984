import React from 'react'
import HowToSteps from '@/components/ui/HowToSteps/HowToSteps'
import { useTranslation } from 'next-i18next'
import HowToStepsItem from '@/components/ui/HowToSteps/HowToStepsItem'
import Container from '@/components/ui/Container/Container'

interface Props {}

const Steps: React.FC<Props> = () => {
  const { t } = useTranslation()

  const items: { title: string; description: string }[] = t('steps.items', { returnObjects: true })

  return (
    <Container className="mt-12 md:mt-24">
      <div className="space-y-12 md:space-y-20">
        <div className="max-w-sm md:mx-auto">
          <h2 className="text-2xl font-semibold md:text-center md:text-3xl">{t('steps.title')}</h2>
        </div>

        {items && typeof items.map === 'function' && (
          <HowToSteps>
            {items.map((feature, index) => (
              <HowToStepsItem
                key={feature.title}
                headline={feature.title}
                text={feature.description}
                number={index + 1}
              />
            ))}
          </HowToSteps>
        )}
      </div>
    </Container>
  )
}

export default Steps
