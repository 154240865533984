import React, { ReactNode } from 'react'
import clsx from 'clsx'

import { Listbox } from '@headlessui/react'

interface Props {
  children: ReactNode
  className?: string
}

const ListboxOptions: React.FC<Props> = ({ children, className = undefined }) => (
  <Listbox.Options
    className={clsx(
      className,
      'absolute left-0 top-full z-50 mt-1 min-w-full space-y-1 rounded-lg bg-white p-1 shadow-xl'
    )}
  >
    {children}
  </Listbox.Options>
)

export default ListboxOptions
