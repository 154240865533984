import React from 'react'
import dynamic from 'next/dynamic'
import Image from 'next/legacy/image'
import Button from '../Button/Button'

interface Props {
  title: string
  subtitle: string
  back?: string
  onBack?: () => void
}

const SuccessAlertAnimation = dynamic(() => import('./_SuccessAlertAnimation'), { ssr: false })

// This component is meant to be imported
// Because I want to make the dynamic import an implementation details
const SuccessAlert: React.FC<Props> = ({ title, subtitle, back = '', onBack = () => {} }) => {
  const [isCompleted, setIsCompleted] = React.useState(false)

  return (
    <div className="flex h-full w-full flex-col justify-around">
      <div>
        <div className="flex h-64 items-center justify-center">
          {!isCompleted && <SuccessAlertAnimation onComplete={() => setIsCompleted(true)} />}
          {isCompleted && (
            <div className="flex h-full items-end py-8 transition-opacity duration-500">
              <Image
                height={128}
                width={128}
                src="/images/icons/successCheckIcon.svg"
                alt="Alert Success"
              />
            </div>
          )}
        </div>
      </div>
      <div className="space-y-2 text-center">
        <h3 className="text-xl font-bold">{title}</h3>
        <h4 className="text-sm text-gray-500">{subtitle}</h4>
      </div>

      {back && (
        <Button onClick={onBack} className="mt-6 min-w-[180px] self-center" variant="tertiary">
          {back}
        </Button>
      )}
    </div>
  )
}

export default SuccessAlert
