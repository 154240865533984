import React, { useState } from 'react'
import axios from 'axios'
import Button from '@/components/ui/Button/Button'
import { useRouter } from 'next/router'
import { API_ROUTES } from 'utils/constants'
import FormInput from '@/components/ui/FormInput/FormInput'
import { useTranslation } from 'next-i18next'
import Container from '@/components/ui/Container/Container'
import { useForm } from 'react-hook-form'
import FormGroup from '@/components/ui/FormGroup/FormGroup'
import { emailValidationPattern } from 'utils/formValidations'
import { useWithId } from 'hooks/useWithId'
import Alert from '@/components/ui/Alert/Alert'
import SuccessAlert from '@/components/ui/SuccessAlert/SuccessAlert'
import SuccessAlertModal from '@/components/ui/SuccessAlert/SuccessAlertModal'
import { ApiError } from 'model/ApiError'
import Logger from 'utils/logger'
import { logGoogleAdsConversion, logGoogleAnalyticEvent } from 'utils/tracking'

interface Props {}

interface FormNewsletterParams {
  email: string
  b_f3bc7a2563fa5450f353c9ddf_3d25f62db0: string
}

const Newsletter: React.FC<Props> = () => {
  const { locale } = useRouter()

  const [loading, setLoading] = useState(false)
  const [apiError, setApiError] = useState<ApiError[]>([])
  const [successModalOpen, setSuccessModalOpen] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormNewsletterParams>()

  const onSubmit = async (data: FormNewsletterParams) => {
    setApiError([])
    setLoading(true)

    axios
      .post(API_ROUTES.NEWSLETTER, {
        email: data.email,
        b_f3bc7a2563fa5450f353c9ddf_3d25f62db0: data.b_f3bc7a2563fa5450f353c9ddf_3d25f62db0,
        locale,
      })
      .then(() => {
        logGoogleAnalyticEvent('newsletter_signup')
        logGoogleAdsConversion('AW-10892640527/D09FCN-ZxNEDEI-Cgsoo')
        setSuccessModalOpen(true)
        reset()
      })
      .catch((error) => {
        Logger.warn('Newsletter sign up failed', error)
        setApiError(error.response.data || [{ code: 'general_error' }])
      })
      .finally(() => setLoading(false))
  }

  const { t } = useTranslation()
  const withId = useWithId()

  return (
    <Container className="mt-12 md:mt-24">
      <div className="border-t border-gray-200 pb-6 pt-12 md:pb-12 md:pt-24">
        <div className="mx-auto max-w-[730px] space-y-6">
          <div className="text-center">
            <h3 className="mb-3 text-2xl font-bold">{t('newsletter.title')}</h3>
            <span className="text-xl">{t('newsletter.description')}</span>
          </div>

          {apiError.length > 0 && <Alert>{t(`apiErrors.${apiError[0].code}`)}</Alert>}

          <SuccessAlertModal onClose={() => setSuccessModalOpen(false)} isOpen={successModalOpen}>
            <SuccessAlert
              title={t('newsletter.success.title')}
              subtitle={t('newsletter.success.description')}
              back={t('newsletter.success.back')}
              onBack={() => setSuccessModalOpen(false)}
            />
          </SuccessAlertModal>

          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col space-y-5">
            <FormGroup htmlFor={withId('email')} required error={errors.email?.message}>
              <FormInput
                type="text"
                placeholder={t('newsletter.placeholder')}
                {...register('email', {
                  required: t('forms.validationErrors.required'),
                  pattern: emailValidationPattern(t('forms.validationErrors.email')),
                })}
              />
            </FormGroup>
            <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
              <FormInput
                type="text"
                tabIndex={-1}
                {...register('b_f3bc7a2563fa5450f353c9ddf_3d25f62db0')}
              />
            </div>

            <Button className="min-w-[144px] self-center" type="submit" loading={loading}>
              {t('newsletter.button')}
            </Button>
          </form>

          <div className="flex flex-col text-center text-sm text-gray-500">
            <span>{t('newsletter.disclaimerOne')}</span>
            <span>{t('newsletter.disclaimerTwo')}</span>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Newsletter
