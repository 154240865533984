import React from 'react'
import clsx from 'clsx'

export type Props = {
  className?: string
  children: React.ReactNode
}

const Container: React.FC<Props> = ({ children, className = undefined }) => (
  <div className={clsx(className, 'mx-auto max-w-[1442px] px-4 md:px-10 xl:px-20')}>{children}</div>
)

export default Container
