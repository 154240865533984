import React, { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react'
import clsx from 'clsx'

export type CommonProps = {
  children: React.ReactNode
  variant?: 'primary' | 'secondary'
  tag?: 'a' | 'button'
}

export type ButtonProps = CommonProps & ButtonHTMLAttributes<HTMLButtonElement>
export type AnchorProps = CommonProps & AnchorHTMLAttributes<HTMLAnchorElement>

const isAnchor = (props: Partial<ButtonProps | AnchorProps>): props is AnchorProps =>
  (props as AnchorProps).tag !== 'button'

export type Props = ButtonProps | AnchorProps

const Anchor: React.FC<Props> = React.forwardRef<HTMLAnchorElement & HTMLButtonElement, Props>(
  (props, ref) => {
    const { children, className, variant = 'primary', ...otherProps } = props

    const computedClassName = clsx(
      className,
      'transition-colors duration-200 ease-in-out',
      variant === 'primary' && 'text-gray-600 hover:text-gray-900',
      variant === 'secondary' && 'text-gray-500 hover:text-gray-900'
    )

    if (isAnchor(props)) {
      return (
        <a ref={ref} {...(otherProps as AnchorProps)} className={computedClassName}>
          {children}
        </a>
      )
    }

    return (
      // eslint-disable-next-line react/button-has-type
      <button ref={ref} {...(otherProps as ButtonProps)} className={computedClassName}>
        {children}
      </button>
    )
  }
)

Anchor.displayName = 'Anchor'

export default Anchor
