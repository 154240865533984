import React from 'react'

import IconBase, { IconProps } from './IconBase'

const IconChevronDown = ({ size, className }: IconProps) => (
  <IconBase size={size} className={className}>
    <path
      d="m4.4697 8.4697c0.29289-0.2929 0.76777-0.2929 1.0607 0l5.4697 5.4696 5.4697-5.4696c0.2929-0.2929 0.7677-0.2929 1.0606 0 0.2929 0.2929 0.2929 0.7677 0 1.0606l-6 6c-0.2929 0.29289-0.7677 0.29289-1.0606 0l-6-6c-0.29289-0.2929-0.29289-0.7677 0-1.0606z"
      clipRule="evenodd"
      fillRule="evenodd"
    />
  </IconBase>
)

export default IconChevronDown
