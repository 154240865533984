import { atom } from 'recoil'
import { type CompanyDetails } from '@/components/shared/CompanyDetailsForm'
import { type ContactDetailsProfile } from '../pages/profile'
import { Locale } from '../generated/graphcms'

export const user = atom<ContactDetailsProfile>({
  key: 'user',
  default: {
    name: '',
    email: '',
    lastName: '',
    firstName: '',
    phone: '',
    companyName: '',
    jobTitle: '',
    loggedIn: false,
    requested: false,
    locale: Locale.En,
  },
})

export const company = atom<CompanyDetails>({
  key: 'company',
  default: {
    companyName: '',
    country: '',
    postCode: '',
    city: '',
    address: '',
    vatNumber: '',
    noVat: false,
  },
})
