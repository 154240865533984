import clsx from 'clsx'
import React, { InputHTMLAttributes, ReactNode, RefAttributes } from 'react'
import IconCheckmark from '../Icon/IconCheckmark'
import styles from './FormCheckbox.module.css'

type Props = InputHTMLAttributes<HTMLInputElement> &
  RefAttributes<HTMLInputElement> & {
    children: ReactNode
  }

const FormCheckbox: React.FC<Props> = React.forwardRef<HTMLInputElement, Partial<Props>>(
  ({ id, children, ...props }, ref) => {
    const selectedMaskClassName = [
      'flex items-center justify-center h-6 w-6 mr-3 rounded-md border flex-shrink-0 text-white cursor-pointer',
      'peer-focus:ring-offset-2 peer-focus:ring-2 peer-focus:ring-gray-200',
      styles['custom-checkbox'],
    ]
      .flat()
      .filter(Boolean)
      .join(' ')

    return (
      <label
        htmlFor={id}
        className="relative inline-flex cursor-pointer text-sm leading-none text-gray-700"
      >
        <input
          ref={ref}
          type="checkbox"
          id={id}
          {...props}
          className={clsx(
            'peer absolute left-0 top-0 h-6 w-6 opacity-0',
            styles['hidden-checkbox']
          )}
        />
        <div className={selectedMaskClassName}>
          <IconCheckmark className="w-3" />
        </div>
        <span className="whitespace-nowrap pt-1 leading-tight">{children}</span>
      </label>
    )
  }
)

FormCheckbox.displayName = 'FormCheckbox'

export default FormCheckbox
