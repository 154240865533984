import axios from 'axios'
import csrfTokenHandler from './csrfTokenHandler'

import { API_URL } from '../utils/constants'

const auth = async (mtoken: string) => {
  await csrfTokenHandler(true)

  return axios.post(`${API_URL}/api/auth/magic-signin`, { mtoken })
}
export default auth
