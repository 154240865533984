import React from 'react'

import IconBase, { IconProps } from './IconBase'

const IconMenu = ({ size, className }: IconProps) => (
  <IconBase size={size} className={className}>
    <path
      d="m3.1159 6.9234c0-0.42056 0.34093-0.76149 0.76149-0.76149h16.245c0.42057 0 0.76149 0.34094 0.76149 0.76149 0 0.42056-0.34092 0.76147-0.76149 0.76147h-16.245c-0.42056 0-0.76149-0.3409-0.76149-0.76147zm0 5.0766c0-0.42057 0.34093-0.76149 0.76149-0.76149h16.245c0.42057 0 0.76149 0.34092 0.76149 0.76149 0 0.42057-0.34092 0.76149-0.76149 0.76149h-16.245c-0.42056 0-0.76149-0.34092-0.76149-0.76149zm0.76149 4.3151c-0.42056 0-0.76149 0.34092-0.76149 0.76149 0 0.4205 0.34093 0.76149 0.76149 0.76149h16.245c0.42057 0 0.76149-0.341 0.76149-0.76149 0-0.42057-0.34092-0.76149-0.76149-0.76149z"
      clipRule="evenodd"
      fillRule="evenodd"
      strokeWidth=".76149"
    />
  </IconBase>
)

export default IconMenu
