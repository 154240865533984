/* eslint-disable @next/next/no-img-element */
import React from 'react'
import Container from 'components/ui/Container/Container'
import Image from 'next/legacy/image'
import Link from 'next/link'
import logoImage from 'public/images/logo/cloopio-logo.svg'
import { useTranslation } from 'next-i18next'
import { FooterData } from '@/components/layout/AppShell/getFooterData'
import { openCookieSettings } from 'utils/tracking'
import { APP_ROUTES } from 'utils/constants'
import Anchor from 'components/ui/Anchor/Anchor'

interface Props {
  data: FooterData
}

const Footer: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation()
  return (
    <footer className="mt-10 md:mt-56">
      <Container className="flex flex-col items-center justify-between space-y-6 border-t border-gray-200 py-6 text-sm leading-none md:space-y-3 md:py-4 lg:flex-row lg:space-y-0 lg:py-5">
        <div>{t('footer.helpline.anyQuestions')}</div>

        <div className="space-y-6 text-center text-gray-500 md:flex md:space-x-5 md:space-y-0 md:text-left">
          <p>
            <span className="block pb-1 md:inline md:pb-0">{t('footer.helpline.email_us')} </span>
            <a
              className="inline-block p-0.5 text-gray-800 hover:underline"
              href={`mailto:${t('footer.helpbox.email')}`}
            >
              {t('footer.helpline.email')}
            </a>
          </p>
          <p>
            <span className="block pb-1 md:inline md:pb-0">{t('footer.helpline.call_at')} </span>
            <a
              className="inline-block p-0.5 text-gray-800 hover:underline"
              href={`tel:${t('footer.helpline.phoneNumberRaw')}`}
            >
              {t('footer.helpline.phoneNumber')}
            </a>
          </p>

          <p>
            <span className="block pb-1 md:inline md:pb-0">{t('footer.helpline.schedule')} </span>
            <a
              className="inline-block p-0.5 text-gray-800 hover:underline"
              href="https://calendly.com/cloopio/support"
              target="_blank"
              rel="noreferrer"
            >
              {t('footer.helpline.meeting')}
            </a>
          </p>
        </div>
      </Container>

      <div className="bg-gray-50 pb-8 pt-10">
        <Container className="space-y-14">
          <div className="grid place-content-center gap-y-4 text-center text-sm md:grid-cols-4 md:place-content-start md:gap-x-5 md:gap-y-0 md:text-left">
            <div>
              <div className="-ml-4 w-32 md:ml-0">
                <Image src={logoImage} alt="Cloopio" />
              </div>
            </div>

            <div className="space-y-5 pt-7 ">
              <p className="text-xs font-semibold uppercase text-gray-600">
                {t('footer.featured.title')}
              </p>
              <nav>
                <ul className="space-y-3 md:space-y-2">
                  {data.areas.map((area) => (
                    <li key={area.slug}>
                      <Link href={`${APP_ROUTES.VENUES}/${area.slug}`} passHref legacyBehavior>
                        <Anchor>{area.title}</Anchor>
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>

            <div className="space-y-5 pt-7 ">
              <p className="text-xs font-semibold uppercase text-gray-600">
                {t('footer.followUs.title')}
              </p>
              <nav>
                <ul className="space-y-3 md:space-y-2">
                  <li>
                    <Link
                      href="https://www.linkedin.com/company/cloopio?utm_source=website&utm_medium=link-out&utm_campaign=footer"
                      passHref
                      legacyBehavior
                    >
                      <Anchor target="_blank">{t('footer.company.linkedIn')}</Anchor>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="https://www.instagram.com/cloopio_com?utm_source=website&utm_medium=link-out&utm_campaign=footer"
                      passHref
                      legacyBehavior
                    >
                      <Anchor target="_blank">{t('footer.company.instagram')}</Anchor>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="https://www.facebook.com/cloopio?utm_source=website&utm_medium=link-out&utm_campaign=footer"
                      passHref
                      legacyBehavior
                    >
                      <Anchor target="_blank">{t('footer.company.facebook')}</Anchor>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="space-y-5 pt-7">
              <p className="text-xs font-semibold uppercase text-gray-600">
                {t('footer.company.title')}
              </p>
              <nav>
                <ul className="space-y-3 md:space-y-2">
                  <li>
                    <Link href={APP_ROUTES.ABOUT} passHref legacyBehavior>
                      <Anchor>{t('footer.company.about')}</Anchor>
                    </Link>
                  </li>
                  <li>
                    <Link href={APP_ROUTES.SUSTAINABILITY} passHref legacyBehavior>
                      <Anchor>{t('footer.company.sustainability')}</Anchor>
                    </Link>
                  </li>
                  <li>
                    <Link href={APP_ROUTES.REFERRAL} passHref legacyBehavior>
                      <Anchor>{t('footer.company.referral')}</Anchor>
                    </Link>
                  </li>
                  <li>
                    <Link href={APP_ROUTES.PRIVACY} passHref legacyBehavior>
                      <Anchor>{t('footer.company.privacy')}</Anchor>
                    </Link>
                  </li>
                  <li>
                    <Link href={APP_ROUTES.TERMS} passHref legacyBehavior>
                      <Anchor>{t('footer.company.terms')}</Anchor>
                    </Link>
                  </li>
                  <li>
                    <Link href={APP_ROUTES.IMPRINT} passHref legacyBehavior>
                      <Anchor>{t('footer.company.imprint')}</Anchor>
                    </Link>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="text-gray-600 transition-colors duration-200 ease-in-out hover:text-gray-900"
                      onClick={openCookieSettings}
                    >
                      {t('footer.company.cookieSettings')}
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div className="text-center md:text-right">
            <p className="text-xs text-gray-600">{t('footer.copyright')}</p>
          </div>
        </Container>
      </div>
    </footer>
  )
}

export default Footer
