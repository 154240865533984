import { captureMessage, captureException, SeverityLevel } from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

const sentryLogger = (message: string | Error, level: SeverityLevel, extra: any) => {
  if (typeof message === 'string') {
    return captureMessage(message, { level, extra })
  }
  return captureException(message, { level, extra })
}

const consoleLogger = (message: string | Error, level: SeverityLevel, extra: any) =>
  console.log(message, { level, extra })

const Logger = {
  async error(message: string, args: any = {}) {
    return this.log(message, 'error', args)
  },
  async warn(message: string, args: any = {}) {
    return this.log(message, 'warning', args)
  },
  async info(message: string, args: any = {}) {
    return this.log(message, 'info', args)
  },
  async log(message: string | Error, level: SeverityLevel, extra: any) {
    if (SENTRY_DSN) {
      return sentryLogger(message, level, extra)
    }
    return consoleLogger(message, level, extra)
  },
}

export default Logger
