import clsx from 'clsx'
import React from 'react'

const Spinner = ({ className = '' }) => (
  <div
    className={clsx('h-10 w-10 animate-spin rounded-full border-l-2 border-gray-600', className)}
  />
)

export default Spinner
