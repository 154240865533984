import React, { ReactNode, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import clsx from 'clsx'
import IconClose from '../Icon/IconClose'

export interface Props {
  children?: ReactNode
  title?: string
  size?: 'sm' | 'lg' | '2xl'
  isOpen: boolean
  closeModal?: (byButton?: boolean) => void
  disableClosing?: boolean
}

const ModalGallery: React.FC<Props> = ({
  children = undefined,
  title = undefined,
  size = 'sm',
  isOpen,
  closeModal = () => {},
  disableClosing = false,
}) => {
  const handleClose = () => {
    if (isOpen) {
      // we do not want to trigger this if the closing impulse is coming from the prop
      closeModal()
    }
  }

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog open={isOpen} as="div" className="relative z-modal" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-800 bg-opacity-60 backdrop-blur-md" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={clsx(
                  'relative w-full transform rounded-lg bg-white px-4 pb-6 pt-10 text-left align-middle shadow-xl transition-all md:px-16 md:py-10',
                  size === 'sm' && 'max-w-md',
                  size === 'lg' && 'max-w-xl',
                  size === '2xl' && 'max-w-4xl'
                )}
              >
                {!disableClosing && (
                  <button
                    onClick={() => closeModal(true)}
                    type="button"
                    className="absolute right-1 top-2 p-2 ring-0 focus:outline-none md:right-2 md:top-2"
                  >
                    <IconClose
                      size="24"
                      className="text-gray-900 transition-colors duration-300 ease-in-out hover:text-gray-400"
                    />
                  </button>
                )}

                {title && (
                  <Dialog.Title as="h3" className="pb-2 text-base font-semibold">
                    {title}
                  </Dialog.Title>
                )}
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default ModalGallery
