import React from 'react'

import IconBase, { IconProps } from './IconBase'

const IconCheckmark = ({ size, className }: IconProps) => (
  <IconBase size={size} className={className}>
    <path
      d="m20.744 5.2645c0.31391 0.2877 0.33508 0.77534 0.04728 1.0892l-11.306 12.334c-0.14207 0.155-0.34131 0.24523-0.55151 0.24979-0.21022 0.004584-0.41318-0.076926-0.56187-0.2256l-5.1393-5.1393c-0.30104-0.30106-0.30104-0.78918 0-1.0902 0.30106-0.30106 0.78918-0.30106 1.0902 0l4.57 4.57 10.762-11.741c0.28768-0.31385 0.77528-0.33506 1.0892-0.047364z"
      clipRule="evenodd"
      fillRule="evenodd"
      strokeWidth="2.0557"
    />
  </IconBase>
)

export default IconCheckmark
