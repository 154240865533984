import { atom } from 'recoil'
import { Props as AlertProps } from '@/components/ui/Alert/Alert'

const alert = atom<{ type: AlertProps['type']; text: string }>({
  key: 'alert',
  default: {
    type: 'success',
    text: '',
  },
})

export default alert
