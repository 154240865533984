import { atom } from 'recoil'
import { type ContactDetails } from '@/components/shared/ContactDetailsForm'
import { MIN_GUESTS } from '../utils/constants'

export const firstStep = atom({
  key: 'firstStepVenueRequest',
  default: {
    dateArrival: '',
    dateDeparture: '',
    groupSize: `${MIN_GUESTS}`,
    roomsSingle: `${MIN_GUESTS}`,
    roomsDouble: '0',
  },
})

export const secondStep = atom({
  key: 'secondStepVenueRequest',
  default: {
    meetingRoomsNumber: '',
    meetingRoomsUnknown: false,
    cateringRequired: false,
    activitiesRequired: true,
    exclusivityRequired: false,
    eventOccasion: '',
    comment: '',
  },
})

export const thirdStep = atom<ContactDetails>({
  key: 'thirdStepVenueRequest',
  default: {
    firstName: '',
    lastName: '',
    companyName: '',
    jobTitle: '',
    email: '',
    phone: '',
    termsConditionsAccepted: false,
  },
})

export const formResponse = atom({
  key: 'formVenueResponse',
  default: {
    requestUuid: '',
    moreVenuesUrl: '',
    similarVenues: [],
  },
})

export const additionalStep = atom({
  key: 'additionalStepVenueRequest',
  default: {
    alternativeDates: '',
    timePeriodArrival: '',
    timePeriodDeparture: '',
    preferredBudget: '',
  },
})
