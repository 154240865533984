import React from 'react'
import Modal from '../Modal/Modal'

interface Props {
  onClose: () => void
  isOpen: boolean
  children: React.ReactNode
}

const SuccessAlertModal: React.FC<Props> = ({ onClose = null, isOpen = true, children }) => (
  <Modal isOpen={isOpen} closeModal={onClose!}>
    <div className="h-96">{children}</div>
  </Modal>
)

export default SuccessAlertModal
