import getCSRF from './getCSRF'

const csrfTokenHandler = async (forceUpdate = false) => {
  try {
    const XSRF_TOKEN_COOKIE = document.cookie
      .split('; ')
      .find((row) => row.startsWith('XSRF-TOKEN='))
      ?.split('=')[1]

    if (!XSRF_TOKEN_COOKIE || forceUpdate) {
      await getCSRF()
    }
  } catch (e) {
    // empty
  }
}
export default csrfTokenHandler
