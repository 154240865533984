import React, { ReactNode } from 'react'
import clsx from 'clsx'

import { Listbox } from '@headlessui/react'
import { IconComponent } from '@/components/ui/Icon/IconBase'
import IconChevronDown from '../Icon/IconChevronDown'

type Props = {
  children: ReactNode
  open?: boolean
  variant?: 'primary' | 'secondary'
  Icon?: IconComponent
  error?: boolean
  cy?: string
}

const ListboxButton: React.FC<Props> = ({
  children,
  open = false,
  variant = 'primary',
  Icon = undefined,
  error = false,
  cy = undefined,
}) => (
  <Listbox.Button
    className={clsx(
      'focus-element flex h-10 w-full items-center justify-between rounded-lg border text-sm leading-none',
      variant === 'primary' && [
        'bg-white ',
        open && 'border-gray-300 ring-2 ring-gray-100',
        !open && 'shadow-sm border-gray-300 ring-0',
        error && 'border-red-600',
      ],
      variant === 'secondary' && ['h-auto border-transparent bg-transparent px-0 text-gray-500'],
      Icon ? 'pl-11 pr-3' : 'px-3'
    )}
    data-cy={cy}
  >
    {Icon && (
      <div className="pointer-events-none absolute left-3 top-0 flex h-10 w-6 items-center justify-center text-gray-500">
        <Icon size="24" />
      </div>
    )}
    <span className="truncate py-1">{children}</span>
    <span className="ml-2">
      <IconChevronDown
        aria-hidden="true"
        className={clsx('transform text-gray-500', open && 'mt-0.5 rotate-180')}
      />
    </span>
  </Listbox.Button>
)

export default ListboxButton
