import React, { FC, ReactNode } from 'react'
import clsx from 'clsx'

interface Props {
  size?: '8' | '16' | '20' | '24' | '32' | '36' | '44' | '56'
  children: ReactNode
  className?: string
  fillNone?: boolean
  strokeCurrent?: boolean
  strokeWidth?: number | string
}

export type IconProps = Pick<Props, 'size' | 'className' | 'fillNone'>

type IconBaseComponent = FC<Props>
export type IconComponent = FC<Omit<Props, 'children'>>

const IconBase: IconBaseComponent = ({
  size = '16',
  children,
  className = undefined,
  fillNone = false,
  strokeCurrent = false,
  strokeWidth = 1,
}) => (
  <svg
    strokeWidth={strokeWidth}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill={clsx(fillNone ? 'none' : 'currentColor')}
    stroke={strokeCurrent ? 'currentColor' : 'none'}
    width={size}
    height={size}
    className={className}
  >
    {children}
  </svg>
)

export default IconBase
