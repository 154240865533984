import React from 'react'

import IconBase, { IconProps } from './IconBase'

const IconClose = ({ size, className }: IconProps) => (
  <IconBase size={size} className={className}>
    <path
      d="m4.9072 4.9072c0.31812-0.31814 0.8339-0.31814 1.152 0l5.9408 5.9408 5.9408-5.9408c0.31818-0.31814 0.834-0.31814 1.1522 0 0.31802 0.31812 0.31802 0.8339 0 1.152l-5.9409 5.9408 5.9409 5.9408c0.31802 0.31818 0.31802 0.834 0 1.1522-0.31818 0.31802-0.834 0.31802-1.1522 0l-5.9408-5.9409-5.9408 5.9409c-0.31812 0.31802-0.8339 0.31802-1.152 0-0.31814-0.31818-0.31814-0.834 0-1.1522l5.9408-5.9408-5.9408-5.9408c-0.31814-0.31812-0.31814-0.8339 0-1.152z"
      clipRule="evenodd"
      fillRule="evenodd"
      strokeWidth="1.6292"
    />
  </IconBase>
)

export default IconClose
