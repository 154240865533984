import React, { useMemo, useEffect } from 'react'
import { omit, pick } from 'lodash'
import { createPopup } from '@typeform/embed'
import { useRecoilState, useSetRecoilState, useResetRecoilState } from 'recoil'
import { APP_ROUTES, TYPEFORM } from 'utils/constants'
import { Listbox } from '@headlessui/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { Locale } from 'generated/graphcms'

import Header from 'components/ui/Header/Header'
import Anchor from 'components/ui/Anchor/Anchor'
import ListboxButton from 'components/ui/Listbox/ListboxButton'
import ListboxOptions from 'components/ui/Listbox/ListboxOptions'
import ListboxOption from 'components/ui/Listbox/ListboxOption'
import IconUser from '@/components/ui/Icon/IconUser'
import { logGoogleAnalyticEvent, logGoogleTagManagerEvent } from 'utils/tracking'
import convertKeysToCamelCase from '../../../utils/convertKeysToCamelCase'
import isMobile from '../../../utils/isMobile'
import getUser from '../../../api/getUser'
import signOut from '../../../api/signOut'
import { user, company } from '../../../store/profile'
import { firstStep, secondStep, thirdStep, additionalStep } from '../../../store/venueRequest'
import alert from '../../../store/global'
import useErrorHandler from '../../../hooks/useErrorHandler'
import '@typeform/embed/build/css/popup.css'

interface Props {
  locationLinkSuffix?: string
  showNavigation?: boolean
}

const HeaderCombined: React.FC<Props> = ({ locationLinkSuffix = '', showNavigation = true }) => {
  const router = useRouter()
  const { locale, pathname, asPath, query } = router

  const errorHandler = useErrorHandler()
  const setAlertVariable = useSetRecoilState(alert)

  const languages = Object.values(Locale) as Locale[]
  const { t } = useTranslation('common')

  const [userVariable, setUserVariable] = useRecoilState(user)
  const setCompanyVariable = useSetRecoilState(company)

  const resetUser = useResetRecoilState(user)
  const resetFirstStep = useResetRecoilState(firstStep)
  const resetSecondStep = useResetRecoilState(secondStep)
  const resetThirdStep = useResetRecoilState(thirdStep)
  const resetAdditionalStep = useResetRecoilState(additionalStep)

  const switchLocaleTo = (newLocale: Locale) => {
    logGoogleTagManagerEvent('language_switch', { newLocale })
    document.cookie = `NEXT_LOCALE=${newLocale}; max-age=31536000; path=/`
    return router.push({ pathname, query }, asPath, { locale: newLocale })
  }

  const { open: openContactForm } = useMemo(
    () => createPopup(locale === Locale.De ? TYPEFORM.CONTACT_ID_DE : TYPEFORM.CONTACT_ID),
    [locale]
  )

  const { open: openPartnerForm } = useMemo(() => createPopup(TYPEFORM.BECOME_PARTNER_ID), [])

  const getInTouch = () => {
    logGoogleAnalyticEvent('free_consultation')
    openContactForm()
  }

  const fetchUser = async () => {
    if (!userVariable.requested) {
      try {
        const res = await getUser()

        if (res.data?.status === 'success' && res.data?.data?.user) {
          const authenticatedUser = res.data.data.user

          setUserVariable({
            locale: '',
            companyName: '',
            email: '',
            firstName: '',
            jobTitle: '',
            lastName: '',
            name: '',
            phone: '',
            loggedIn: true,
            requested: true,
            ...convertKeysToCamelCase(omit(authenticatedUser, 'customer')),
          })

          const customer = pick(authenticatedUser, 'customer')

          if (customer?.customer?.legal_address) {
            // @ts-ignore
            setCompanyVariable(convertKeysToCamelCase(customer?.customer?.legal_address))
          }
        } else {
          setUserVariable({
            ...userVariable,
            loggedIn: false,
            requested: true,
          })
        }
      } catch (e) {
        setUserVariable({
          ...userVariable,
          loggedIn: false,
          requested: true,
        })
      }
    }
  }

  useEffect(() => {
    fetchUser()
  }, [])

  const accountListHandler = async (type: 'profile' | 'dashboard' | 'logout') => {
    // eslint-disable-next-line default-case
    switch (type) {
      case 'profile':
        router.push(APP_ROUTES.PROFILE)
        break
      case 'dashboard':
        router.push(APP_ROUTES.DASHBOARD)
        break
      case 'logout':
        try {
          const res = await signOut()
          if (res?.data?.status !== 'success') {
            const text = await errorHandler(res.data)
            setAlertVariable({ type: 'error', text })
          }
        } catch (e) {
          await errorHandler(e, 'Failed to sign out')
        }

        router.push(APP_ROUTES.HOME)

        resetUser()
        resetFirstStep()
        resetSecondStep()
        resetThirdStep()
        resetAdditionalStep()

        localStorage.clear()
        sessionStorage.clear()
        break
    }
  }

  const navigation = () =>
    showNavigation ? (
      <div className="flex flex-col items-start space-y-5 lg:flex-row lg:space-x-10 lg:space-y-0">
        <Link href={APP_ROUTES.VENUES + locationLinkSuffix} passHref legacyBehavior>
          <Anchor>{t('header.navigation.venues')}</Anchor>
        </Link>
        <Link href={APP_ROUTES.ABOUT} passHref legacyBehavior>
          <Anchor>{t('header.navigation.about')}</Anchor>
        </Link>
        <Link href={APP_ROUTES.BLOG} passHref legacyBehavior>
          <Anchor>{t('header.navigation.blog')}</Anchor>
        </Link>
        <button
          className="text-gray-600 transition-colors duration-200 ease-in-out hover:text-gray-900"
          onClick={openPartnerForm}
          type="button"
        >
          {t('header.venueContactButton')}
        </button>
        <button
          className="text-gray-600 transition-colors duration-200 ease-in-out hover:text-gray-900"
          onClick={getInTouch}
          type="button"
        >
          {t('header.consultationButton')}
        </button>
      </div>
    ) : null

  const extras = () => (
    <div className="flex flex-col space-y-5 text-sm lg:flex-row lg:items-center lg:space-x-8 lg:space-y-0">
      <div className="flex flex-col space-y-5 lg:flex-row lg:items-center lg:space-x-6 lg:space-y-0">
        <div className="inline-flex">
          <Listbox as="div" className="relative" value={locale} onChange={switchLocaleTo}>
            {({ open }) => (
              <>
                <ListboxButton variant="secondary" open={open}>
                  {t(`language.${locale}`)}
                </ListboxButton>
                <ListboxOptions>
                  {languages.map((language) => (
                    <ListboxOption key={language} value={language}>
                      {t(`language.${language}`)}
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </>
            )}
          </Listbox>
        </div>
      </div>
      {userVariable.loggedIn ? (
        <Listbox as="div" className="relative" onChange={accountListHandler}>
          {({ open }) => (
            <>
              <ListboxButton variant="secondary" open={isMobile() || open}>
                <div className="flex items-center gap-1">
                  <IconUser />
                  {userVariable.name !== userVariable.email
                    ? userVariable.firstName
                    : t('auth.account')}
                </div>
              </ListboxButton>
              <ListboxOptions>
                <ListboxOption value="profile">{t('profile.profileTitle')}</ListboxOption>
                <ListboxOption value="dashboard">{t('dashboard.dashboardTitle')}</ListboxOption>
                <ListboxOption value="logout">{t('auth.logOutButton')}</ListboxOption>
              </ListboxOptions>
            </>
          )}
        </Listbox>
      ) : (
        pathname !== APP_ROUTES.AUTHENTICATION && (
          <div
            className={`${
              locale === Locale.En ? 'w-36' : 'w-44'
            } relative flex items-center justify-between`}
          >
            <button
              className="p-2 font-normal transition-all duration-150 hover:font-semibold"
              onClick={() =>
                router.push({ pathname: APP_ROUTES.AUTHENTICATION, query: { type: 'sign_up' } })
              }
              type="button"
            >
              {t('auth.signUp')}
            </button>
            <span className="absolute left-[50%]">|</span>
            <button
              className="p-2 font-normal transition-all duration-150 hover:font-semibold"
              onClick={() =>
                router.push({ pathname: APP_ROUTES.AUTHENTICATION, query: { type: 'log_in' } })
              }
              type="button"
            >
              {t('auth.logIn')}
            </button>
          </div>
        )
      )}
    </div>
  )

  return <Header navigation={navigation} extras={extras} />
}

export default HeaderCombined
