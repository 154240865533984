import React from 'react'

export interface Props {
  headline: string
  text: string
  number: number
}

const HowToStepsItem: React.FC<Props> = ({ headline, text, number }) => (
  <div className="group relative mb-1 flex pb-6 md:mb-0 md:max-w-[300px] md:flex-col md:items-center md:pb-0 md:text-center">
    <div className="relative z-30 flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border border-gray-300 bg-white text-xs leading-none text-gray-800 md:h-10 md:w-10 md:text-sm">
      {number}
    </div>
    <div className="space-y-2 pl-4 pt-1 md:space-y-4 md:pl-0 md:pt-7">
      <h3 className="text-base font-semibold md:text-xl">{headline}</h3>
      <p className="text-sm md:text-base">{text}</p>
    </div>
    <div className="absolute left-1/2 top-[19px] z-10 hidden h-px w-full bg-gray-200 group-last:hidden md:block" />
    <div className="absolute right-1/2 top-[19px] z-10 hidden h-px w-full bg-gray-200 group-first:hidden md:block" />
    <div className="absolute left-0 top-0 z-20 h-9 w-10 bg-white md:right-0 md:mx-auto md:h-10 md:w-16" />
    <div className="absolute left-4 top-0 z-10 h-full w-px bg-gray-200 group-last:hidden md:hidden" />
  </div>
)

export default HowToStepsItem
